import React, { FC,useState } from "react";
import logo from './LogoHasselt.svg';
import './App.css';
import Lottie from "lottie-react";
import AnimationComingSoon from "./AnimationComingSoon.json";
import axios from "axios";

function App() {
  const [ errCheck, setErrCheck ] = useState(false);
  const [ errNaam, setErrNaam ] = useState(false);
  const [ errAdres, setErrAdres ] = useState(false);
  const [ errTelefoon, setErrTelefoon ] = useState(false);
  const [ errEmail, setErrEmail ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ errorType, setErrorType ] = useState("");
  const [ success, setSuccess ] = useState(false)

  function isValidPhone(phoneNumber) {
    var found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
    if(found > -1) {
        return true;
    }
    else {
        return false;
    }
}
  const handleSubmit = (event:any)  => {  
    event.preventDefault();
    setError(false)
    setSuccess(false)
    console.log("alain",event)
    let naam = event.target[0].value,
        email = event.target[2].value,
        telefoon = event.target[3].value,
        adres = event.target[1].value,
        check = event.target[4].checked;
        console.log("naam",naam)
        console.log("email",email)
        console.log("telefoon",telefoon)
        console.log("adres",adres)
        console.log("check",check)
        let err = 0;
        if(check=== true){
          setErrCheck(false)
        }else{
          setErrCheck(true)
          err++
        }

        if(naam.length <= 2){
          setErrNaam(true)
          err++
        }else{setErrNaam(false)}

        if(adres.length <= 2){
          setErrAdres(true)
          err++
        }else{setErrAdres(false)}

        if(telefoon.length > 0 ){
          let phoneCheck=/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
          phoneCheck.test(telefoon) ? setErrTelefoon(false) : setErrTelefoon(true);
          phoneCheck.test(telefoon) ? setErrTelefoon(false) : err++;
          
        }else{
          setErrTelefoon(false)
        }

        if(email){
          let emailCheck=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          emailCheck.test(email) ? setErrEmail(false) : setErrEmail(true)
          emailCheck.test(email) ? setErrEmail(false) : err++
        }else{
          setErrEmail(true);
          err++
        }

        console.log("errCheck",errCheck)
        console.log("errAdres",errAdres)
        console.log("errNaam",errNaam)
        console.log("errEmail",errEmail)
        console.log("errTelefoon",errTelefoon,telefoon.length )

        if(err === 0){
          
          axios.post("https://backend.develop.cs.hasselt.flykube.agifly.cloud/contacts/create_contact", {
            naam,
            adres,
            email,
            telefoon,
          })
          .then((response) => {
            setSuccess(true)
          })
          .catch((error) => {
            console.log(error)
            setError(true)
            setSuccess(false)
          })
        }


  }

  return (
    <div className="bg-main-hasselt h-full  max-h-screen">
      <header>
        <nav className="bg-white px-2 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 border-b border-gray-200 h-20 md:h-20 xl:h-28 2xl:h-36">
          <div className="container flex flex-wrap items-center justify-between mx-auto h-full  md:p-0 xl:p-2 2xl:p-5">
            <a href="#" className="flex items-cente h-full">
                <img src={logo} className="sm:h-9 md:h-full" alt="Hasselt Logo" />
            </a>
          </div>
        </nav>
      </header>
      <main>
        <div className=" w-full pt-20 md:pt-20 xl:pt-32 2xl:pt-32 w-full">
          <div className="sm:flex content-center	justify-items-center	justify-center items-center		">
            <div className="flex-none w-screen	sm:w-1/3">
              <Lottie animationData={AnimationComingSoon} loop={true} autoplay={true} className="inset-y-0 left-0" />
            </div>
            <div className="md:grow p-5 sm:pr-5">
              <h1 className="text-2xl md:text-4xl xl:text-6xl  2xl:text-8xl font-bold text-center">COMING SOON</h1>
              <div className="flex items-center justify-center w-full ">
                <div className="w-full max-w-3xl mt-5 p-4 bg-white rounded-lg shadow-xl sm:p-6 md:p-8">
                    <form className="space-y-3" onSubmit={handleSubmit}>
                        <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">Binnenkort gaat er een renovatiebegeleider van start in Hasselt. Wil je graag op de hoogte gehouden worden of wil je nu al opgebeld worden om een afspraak in te boeken met de renovatiebegeleider? Laat dan hieronder je gegevens achter.</p>
                        <p className="md:text-xs xl:text-sm  2xl:text-base text-gray-900">Enkel je naam en mailadres is voldoende om op de hoogte gehouden te worden, als je je telefoonnummer opgeeft, belt de renovatiebegeleider je op om een afspraak vast te leggen.</p>
                        <div>
                            <label for="naam" className="block md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Naam en Voornaam</label>
                            <input type="text" name="naam" id="naam" className={errNaam? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5": "bg-gray-50 border border-gray-300 text-gray-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5"} placeholder="vb. Jan Jansens" required />
                        </div>
                        <div>
                            <label for="adres" className="block md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Adres</label>
                            <input type="text" name="adres" id="adres" className={errAdres? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900 md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5"} placeholder="vb. Limburgplein 1, 3500 Hasselt" required />
                        </div>
                        <div>
                            <label for="email" className="block md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">E-mail</label>
                            <input type="email" name="email" id="email" className={errEmail? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900 md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5"} placeholder="vb. e-mail@gmail.be" required />
                        </div>
                        <div>
                            <label for="telefoon" className="block md:text-xs xl:text-sm 2xl:text-base font-medium text-gray-900">Telefoon nummer (niet verplicht)</label>
                            <input type="text" name="telefoon" id="telefoon" className={errTelefoon? "outline-red-500 bg-red-50 border border-red-500 text-red-900  md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5":"bg-gray-50 border border-gray-300 text-gray-900 md:text-xs xl:text-sm 2xl:text-base rounded-lg block w-full p-2.5"} placeholder="vb. 0475 23 90 00" />
                        </div>
                        <div className="flex items-start">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="check" type="checkbox" value="" className={errCheck ? "w-4 h-4 text-red-500 border-1 bg-red-100 border-red-500 rounded focus:ring-red-500 focus:ring-offset-red-500 accent-checkbox":"w-4 h-4 accent-checkbox border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-tail-300"}  />
                                </div>
                                <label for="check" className={errCheck ? "ml-2 text-sm font-medium text-red-500" : "ml-2 text-sm font-medium text-gray-900"}>Ik heb de algemene voorwaarden gelezen en ga hiermee akkoord.</label>
                            </div>
                        </div>
                        {error ? 
                          <div class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg clear-both	" role="alert">
                            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                              <span class="sr-only">Info</span>
                            <div>
                              <span class="font-medium">Er heeft zich een probleem voorgedaan!</span> Probeer het later nog eens opnieuw.
                            </div>
                          </div>
                        :null}

                        {!success ? <button type="submit" className="float-right text-white bg-button hover:bg-button-dark focus:ring-4 focus:outline-none focus:ring-teal-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center content-end	">Hou me de hoogte!</button> : null}
                        {success ? 
                          <div class="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
                            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <span class="sr-only">Info</span>
                            <div>
                              <span class="font-medium">Het formulier is met success verzonden !</span> Iemand binnen ons team neemt weldra contact op met u.
                            </div>
                          </div>
                        : null}


                    </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
